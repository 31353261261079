import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import React from "react"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import Newsletter from '../components/Newsletter'

export default function Home({ data }) {
  const posts = data.posts.nodes;
  return (
    <Layout>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>

        <meta name="description" content={data.site.siteMetadata.description} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={data.site.siteMetadata.ogimg} />
        <meta property="og:image:alt" content="Use Less Divs" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@JocoPolgar" />
        <meta name="twitter:creator" content="@JocoPolgar" />
        <meta name="twitter:url" content={data.site.siteMetadata.url} />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:description" content={data.site.siteMetadata.description} />
        <meta name="twitter:image" content={data.site.siteMetadata.ogimg} />
        <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="sr-only">
                You are on the main page of Use Less Divs blog
              </h1>
              <section>
                <h2 className="h2">Latest Posts</h2>
                {posts.map(post => (
                  <article className="article" key={post.id}>
                    <Link to={"/blog/" + post.frontmatter.slug} aria-label={"Read more about " + post.frontmatter.title} >
                      <h3 className="article__title">
                        <span>
                          { post.frontmatter.title }
                        </span>
                      </h3>
                    </Link>
                    <time className="article__date">{ post.frontmatter.dateCreated }</time>
                    <p>
                    { post.frontmatter.lead }
                    </p>
                  </article>
                ))}
              </section>
              <Newsletter />
            </div>
            <Sidebar />
          </div>
        </div>
      </main>
    </Layout>
  )
}

// export page query
export const query = graphql`
query MyQuery {
  posts: allMdx(sort: {fields: frontmatter___dateCreated, order: DESC}, limit: 20) {
    nodes {
      frontmatter {
        lead
        slug
        title
        dateCreated(formatString: "DD. MMMM YYYY.")
      }
      id
    }
  }
  site {
    siteMetadata {
      description
      ogimg
      title
      url
    }
  }
}
`