import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

export default function Sidebar() {
  const data = useStaticQuery(graphql`
    query topPostQuery {
      topPosts: allMdx(
        sort: { fields: frontmatter___dateCreated, order: DESC }
        limit: 10
      ) {
        nodes {
          frontmatter {
            slug
            title
          }
          id
        }
      }
    }
  `)

  const posts = data.topPosts.nodes

  return (
    <div className="col-lg-4 offset-lg-1">
      <aside>
        <section className="aside-section">
          <h2 className="h2">Top 10 posts</h2>
          <ol className="list-unstyled top-articles mb-0">
          {posts.map(post => (
            <li key={post.id}>
              <Link className="top-article" to={ "/blog/" +  post.frontmatter.slug } aria-label={"Read more about " + post.frontmatter.title} >
                { post.frontmatter.title }
              </Link>
            </li>
          ))}
          </ol>
        </section>
        <section className="aside-section">
          <h2 className="h2">Social</h2>
          <div className="d-flex flex-wrap justify-content-between">
            <a
              href="https://www.linkedin.com/in/jozsef-polgar/"
              className="social-link"
              aria-label="My linkedin profile"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                width="50"
                height="49"
                viewBox="0 0 50 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9703 49H0.809375V16.2859H10.9703V49ZM5.88438 11.8234C2.63594 11.8234 0 9.13281 0 5.88438C0 2.63594 2.63594 0 5.88438 0C9.13281 0 11.7688 2.63594 11.7688 5.88438C11.7688 9.13281 9.13281 11.8234 5.88438 11.8234ZM49 49H38.8609V33.075C38.8609 29.2797 38.7844 24.4125 33.5781 24.4125C28.2953 24.4125 27.4859 28.5359 27.4859 32.8016V49H17.3359V16.2859H27.0813V20.7484H27.2234C28.5797 18.1781 31.8938 15.4656 36.8375 15.4656C47.1188 15.4656 49.0109 22.2359 49.0109 31.0297V49H49Z"
                  fill="#00FFD1"
                />
              </svg>
            </a>
          </div>
        </section>
      </aside>
    </div>
  )
}
