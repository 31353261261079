import React from 'react'

export default function Newsletter() {
    return (

        <section className="newsletter">
          <h2 className="h2">Stay in flow</h2>
          <p>
          Sign up for the newsletter to be among the first to be informed about new articles, improvements and other extra content.
          </p>



        <form action="https://uselessdivs.us19.list-manage.com/subscribe/post?u=9c25c97f06bf39ce1cdee20cb&amp;id=ad4af65570" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="subscribe-form validate" target="_blank" noValidate>
          <fieldset className="d-flex flex-wrap">
            <legend className="sr-only">
              Subscribe to my newsletter
            </legend>
            <div className="input">
              <label className="input__label" htmlFor="mce-EMAIL">Email Address
              <input type="email" name="EMAIL" className="required email input__field" id="mce-EMAIL"/>
            </label>
            </div>
            <div className="input">
              <label className="input__label" htmlFor="mce-NAME">Name
              <input type="text" name="NAME" className="input__field" id="mce-NAME"/>
              </label>
            </div>
            <div hidden aria-hidden="true"><input type="text" name="b_9c25c97f06bf39ce1cdee20cb_ad4af65570" tabIndex="-1"/></div>
          </fieldset>
          <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="primary-btn"/></div>
        </form>
      </section>
    )
}
